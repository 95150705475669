<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">General Report</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="#">Purchase Report</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Bills Wise
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Purchase Report</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-6 d-flex justify-content-end pr-2 pb-2">
        <div class="bg-theam-secondary d-flex m-2 p-1">
          <div class="report-tool-icon mr-3 ml-1">
            <i class="fas fa-print" @click="print"></i>
          </div>
          <div class="report-tool-icon mr-1">
            <i class="fas fa-download" @click="download"></i>
          </div>
        </div>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head pt-0">
      <div class="row">
        <div class="filter-input col-md-12 d-block mr-0 pr-0">
          <select
            class="mg-t-5 mr-2"
            v-model="bill_wise_params.pageIndex"
            @change="selectTotalData"
          >
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
            <option value>All</option>
          </select>
          <select
            class="mg-t-5 mr-2"
            v-model="bill_wise_params.fiscal_year"
            @change="getFilteredDataWithoutDates"
          >
            <option
              v-for="(year, index) in sessionYears"
              :key="index"
              :value="year"
            >
              {{ year }}
            </option>
          </select>
          <select
            v-model="bill_wise_params.branch"
            class="filter-input mg-t-5 mr-2"
            @change="selectBranch"
          >
            <option value="">All Branch</option>
            <option
              v-for="(branch, index) in slipSettings.branches"
              :key="index"
              :value="branch.id"
            >
              {{ branch.name }}
            </option>
          </select>
          <SearchDayMonthComponent
            @clicked="monthDayClicked"
          ></SearchDayMonthComponent>
          <div class="d-inline-block mr-2">
            <label class="filter-lavel mb-0 mr-1 mg-t-5">From</label>
            <input
              type="date"
              v-model="bill_wise_params.from_date"
              placeholder="From"
              class="filter-input mr-1 mg-t-5"
              @change="selectFromDate"
            />
            <label class="filter-lavel mb-0 mr-1 mg-t-5">To</label>
            <input
              type="date"
              v-model="bill_wise_params.to_date"
              placeholder="To"
              class="mg-t-5 filter-input"
              @change="selectEndDate"
            />
          </div>
          <input
            type="text"
            v-model="bill_wise_params.searched"
            class="filter-input mg-t-5 mr-2"
            placeholder="Search..."
            @keyup="searchedKeyword"
          />
          <div class="float-right mr-3 mg-t-5">
            <select
              class="filter-input ml-2 float-right"
              v-model="route"
              @change="filterReportType"
            >
              <option value="/general-report/purchase">Bills Wise</option>
              <option value="/general-report/purchase/branch-wise">
                Branch Wise
              </option>
              <option value="/general-report/purchase/day-wise">
                Day Wise
              </option>
              <option value="/general-report/purchase/month-wise">
                Month Wise
              </option>
              <option value="/general-report/purchase/supplier-wise">
                Supplier Wise
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-theam-secondary table-search-head">
      <div>
        <span class="font-s tx-bold mr-2 tx-primary"
          >Total Purchase:
          {{ parseDigitForSlip(billWiseAttributes.total_purchase) }},</span
        >
        <span class="font-s tx-bold mr-2 tx-primary"
          >Credit: {{ parseDigitForSlip(billWiseAttributes.total_due) }},</span
        >
        <span class="font-s tx-bold mr-2 tx-primary"
          >Bills Count:
          {{ parseDigitForSlip(billWiseAttributes.total_bills) }},</span
        >
        <span class="font-s tx-bold mr-2 tx-primary"
          >Import:
          {{
            parseDigitForSlip(billWiseAttributes.total_export_purchase)
          }}</span
        >
        <span class="font-s tx-bold mr-2 tx-primary"
          >Local Purchase:
          {{ parseDigitForSlip(billWiseAttributes.total_local_purchase) }}</span
        >
        <span class="font-s tx-bold mr-2 tx-primary"
          >VAT able Purchase:
          {{ parseDigitForSlip(billWiseAttributes.total_vatable_amount) }}</span
        >
        <span class="font-s tx-bold mr-2 tx-primary"
          >VAT:
          {{ parseDigitForSlip(billWiseAttributes.total_vat_amount) }}</span
        >
        <span class="font-s tx-bold mr-2 tx-primary"
          >Order:
          {{
            parseDigitForSlip(billWiseAttributes.total_purchase_orders)
          }}</span
        >
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N.</th>
              <th class="wd-10p">Date</th>
              <th class="wd-10p">Bill No</th>
              <th class="wd-15p">Supplier Name</th>
              <th class="wd-10p">Purchase Amt.</th>
              <th class="wd-5p">VAT</th>
              <th class="wd-10p">Bill Amt.</th>
              <th class="wd-10p">Paid Amt.</th>
              <th class="wd-10p">Payment mode</th>
              <th class="wd-5p">Due</th>
              <th class="wd-10p text-center table-end-item">Detail</th>
            </tr>
          </thead>
          <tbody v-if="!loading && billWiseDatas.length > 0">
            <tr v-for="(purchase, index) in billWiseDatas" :key="index">
              <th scope="row" class="table-start-item">
                {{ pageSerialNo + index }}
              </th>
              <td>{{ purchase.date }}</td>
              <td>{{ purchase.prefix }}{{ purchase.purchase_no }}</td>
              <td>
                <span v-if="purchase.supplier">
                  {{ purchase.supplier.name }}
                </span>
                <span v-else>-</span>
              </td>
              <td>{{ parseDigitForSlip(purchase.purchase_amount) }}</td>
              <td>{{ parseDigitForSlip(purchase.vat_amount) }}</td>
              <td>{{ parseDigitForSlip(purchase.bill_amount) }}</td>
              <td>{{ parseDigitForSlip(purchase.paid_amount) }}</td>
              <td>
                <span
                  v-if="purchase.payment_methods && purchase.paid_amount > 0"
                >
                  {{ purchase.payment_methods.title }}
                  <span v-if="purchase.bank">
                    - {{ purchase.bank_detail.bank }}</span
                  >
                </span>
                <span v-else> Credit </span>
              </td>
              <td>{{ parseDigitForSlip(purchase.due_amount) }}</td>
              <td class="text-center table-end-item">
                <a href="javascript:;">
                  <i
                    class="fas fa-eye tx-success"
                    title="View Bill"
                    @click="view('purchaseSlip', purchase.id)"
                  ></i>
                </a>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="error">
            <tr>
              <td colspan="11" style="text-align: center">
                {{ errorMessage }}
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="!loading && billWiseDatas.length == 0">
            <tr>
              <td colspan="11" style="text-align: center">No records found.</td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td colspan="11" style="text-align: center">Loading.....</td>
            </tr>
          </tbody>
        </table>
        <Paginate
          v-model="page"
          :pageCount="totalPageCount"
          :containerClass="'pagination'"
          :clickHandler="clickCallback"
          v-if="totalPageCount > 1"
        />
        <PurchaseSlip @printSlip="printSlip" />
      </div>
    </div>
  </div>
</template>
<script>
import PurchaseSlip from "../../Purchase/PurchaseSlip";
import Paginate from "vuejs-paginate";
import SearchDayMonthComponent from "../../../../shared/SearchDayMonthComponent";
import { mapGetters } from "vuex";
import Services from "./Services/Services";
import _ from "lodash";

export default {
  components: {
    PurchaseSlip,
    Paginate,
    SearchDayMonthComponent,
  },
  data() {
    return {
      sessionYears: [],
      route: this.$route.path,
      bill_wise_params: {
        pageIndex: 15,
        branch: "",
        day: new Date().getDate(),
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
        from_date: "",
        to_date: "",
        searched: "",
        offset: 0,
        fiscal_year: "2021-22",
      },
      excel_wise_params: {
        pageIndex: 15,
        branch: "",
        day: new Date().getDate(),
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
        from_date: "",
        to_date: "",
        searched: "",
        offset: 0,
        fiscal_year: "2021-22",
      },
      errorMessage: "",
      loading: false,
      error: false,
      totalPageCount: 0,
      pageReload: "",
      pageSerialNo: 1,
      page: 1,
    };
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists2",
      "dataLists9",
      "removeList",
      "eventMessage",
      "modalId",
    ]),
    ...mapGetters("purchaseReports", ["billWiseDatas", "billWiseAttributes"]),
    ...mapGetters("purchase", ["slipSettings", "dataSets"]),
  },
  beforeMount() {
    this.getFilteredDataWithoutDates();
    Services.getSlipPredefinedData()
      .then((response) => {
        this.$store.commit("purchase/setSlipSettings", response.data.data);
        this.sessionYears = response.data.data.fiscalYears;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    monthDayClicked(value) {
      if (value) {
        this.bill_wise_params.from_date = "";
        this.bill_wise_params.to_date = "";
        this.bill_wise_params.day = value.day;
        this.bill_wise_params.month = value.month;
        this.bill_wise_params.year = value.year;
      }
      //make api call
      this.page = 1;
      this.pageSerialNo = 1;
      this.bill_wise_params.offset = 0;
      this.getFilteredData();
    },
    print(){
      window.print();
    },
     download(){
      Services.getBillWiseExcel(this.excel_wise_params).then((response)=>{
        const blob = new Blob([response.data], { type: 'application/vnd.ms-excel;charset=utf-8' });
        const elink = document.createElement("a");
            elink.download = 'purchase-bill-wise-report.xlsx';
            elink.style.display = "none";
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL (elink.href); // release the URL object
            document.body.removeChild(elink);
      }).catch(err=>{
        console.log(err);
      });
    },
    filterReportType() {
      if (this.$route.path != this.route) {
        this.$router.push(this.route);
      }
    },
    // pagination clicked
    clickCallback: function (page) {
      if (page == 1) {
        this.pageSerialNo = 1;
      } else {
        this.pageSerialNo = (page - 1) * this.bill_wise_params.pageIndex + 1;
      }
      this.bill_wise_params.offset =
        (page - 1) * this.bill_wise_params.pageIndex;
      this.getFilteredDataWithoutDates();
    },

    //################ Searched Keyword ##################
    searchedKeyword: _.debounce(function () {
      this.page = 1;
      this.pageSerialNo = 1;
      this.bill_wise_params.offset = 0;
      this.getFilteredDataWithoutDates();
    }, 500),

    selectTotalData() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.bill_wise_params.offset = 0;
      this.getFilteredDataWithoutDates();
    },
    selectBranch() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.bill_wise_params.offset = 0;
      this.getFilteredDataWithoutDates();
    },
    selectFromDate() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.bill_wise_params.offset = 0;
      this.getFilteredDataWithoutDates();
    },
    selectEndDate() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.bill_wise_params.offset = 0;
      this.getFilteredDataWithoutDates();
    },
    // today/month section
    getFilteredData() {
      this.loading = true;
      let params = {
        pageIndex: this.bill_wise_params.pageIndex,
        branch: this.bill_wise_params.branch,
        today: this.bill_wise_params.day,
        month: this.bill_wise_params.month + 1,
        year: this.bill_wise_params.year,
        from_date: this.bill_wise_params.from_date,
        to_date: this.bill_wise_params.to_date,
        searched: this.bill_wise_params.searched,
        offset: this.offset,
        fiscal_year: this.bill_wise_params.fiscal_year,
      };
      this.excel_wise_params = {
        pageIndex: this.bill_wise_params.pageIndex,
        branch: this.bill_wise_params.branch,
        today: this.bill_wise_params.day,
        month: this.bill_wise_params.month + 1,
        year: this.bill_wise_params.year,
        from_date: this.bill_wise_params.from_date,
        to_date: this.bill_wise_params.to_date,
        searched: this.bill_wise_params.searched,
        offset: this.offset,
        fiscal_year: this.bill_wise_params.fiscal_year,
      };
      this.ajaxRequest(params);
    },
    // without today/month section
    getFilteredDataWithoutDates() {
      this.loading = true;
      let params = {
        pageIndex: this.bill_wise_params.pageIndex,
        branch: this.bill_wise_params.branch,
        today: "",
        month: "",
        year: this.bill_wise_params.year,
        from_date: this.bill_wise_params.from_date,
        to_date: this.bill_wise_params.to_date,
        searched: this.bill_wise_params.searched,
        offset: this.offset,
        fiscal_year: this.bill_wise_params.fiscal_year,
      };
      this.excel_wise_params = {
        pageIndex: this.bill_wise_params.pageIndex,
        branch: this.bill_wise_params.branch,
        today: "",
        month: "",
        year: this.bill_wise_params.year,
        from_date: this.bill_wise_params.from_date,
        to_date: this.bill_wise_params.to_date,
        searched: this.bill_wise_params.searched,
        offset: this.offset,
        fiscal_year: this.bill_wise_params.fiscal_year,
      };
      this.ajaxRequest(params);
    },
    ajaxRequest(params) {
      this.error = false;
      Services.getBillWiseFilterDatas(params)
        .then((res) => {
          this.$store.commit("purchase/setDataSets", res.data.data.data);
          this.$store.commit(
            "purchaseReports/setBillWiseAttributes",
            res.data.data.attributes
          );
          this.$store.commit(
            "purchaseReports/setBillWiseDatas",
            res.data.data.data
          );
          this.totalPageCount = res.data.data.total_no_of_pages;
          this.loading = false;
        })
        .catch((error) => {
          this.error = true;
          console.log(error);
          if (
            !Object.prototype.hasOwnProperty.call(
              error,
              "response.data.error_message"
            )
          ) {
            if (error.response.data.error_message == "Access Denied.") {
              this.errorMessage = "Access Denied !!!";
            } else {
              this.errorMessage = "Error fetching data from the server.";
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    printSlip(id) {
      //once the modal is opened popup print window
      window.print(id);
      // window.onafterprint = this.afterPrint(id);
    },
    view(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "read" });
    },
  },
  destroyed() {
    this.$store.dispatch("destroyEvent");
  },
};
</script>